import { createApi } from '@reduxjs/toolkit/query/react';

import { SearchLetterDto } from '~/classes/Dto/SearchLetterDto';
import { UpdateLetterDto } from '~/classes/Dto/UpdateLetterDto';
import Letter from '~/classes/Letter';
import LetterBox from '~/classes/LetterBox';
import Pagination from '~/classes/Pagination';
import { ServiceType } from '~/classes/User';
import axios, { axiosBaseQuery } from '~/helper/axios';

const letterBoxs = async (serviceType: ServiceType): Promise<LetterBox[]> => {
  const { data } = await axios.get<LetterBox[]>('/letter-box', { params: { serviceType } });

  return data.map((letterBox) => new LetterBox(letterBox));
};

export const letterApi = createApi({
  reducerPath: 'letterApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getLetterList: builder.query<Pagination<Letter>, SearchLetterDto>({
      query: (params) => ({ url: '/letter', method: 'get', params }),
      transformResponse: (data: Pagination<Letter>) => new Pagination(data, Letter),
    }),
    getLetter: builder.query<Letter, number>({
      query: (letterId) => ({ url: `/letter/${letterId}`, method: 'get' }),
      transformResponse: (data: Letter) => new Letter(data),
    }),
    patchLetter: builder.query<void, UpdateLetterDto>({
      query: ({ id, ...data }) => ({ url: `/letter/${id}`, method: 'patch', data }),
    }),
    patchLetterConfirm: builder.query<void, UpdateLetterDto>({
      query: ({ id, ...data }) => ({ url: `/letter/${id}/confirm`, method: 'patch', data }),
    }),
  }),
});

export const {
  useGetLetterListQuery,
  useGetLetterQuery,
  useLazyPatchLetterQuery,
  useLazyPatchLetterConfirmQuery,
} = letterApi;

export default {
  letterBoxs,
};
