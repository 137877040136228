import { Button, Form, Input, Select, message } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLazyPostCouponQuery } from '~/api/CouponAPI';
import { BenefitPeriod, CouponType } from '~/classes/Coupon';
import { CreateCouponDto } from '~/classes/Dto/CreateCouponDto';
import { FlexEndContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';

const CouponCreate = () => {
  const navigate = useNavigate();
  const [postCoupon] = useLazyPostCouponQuery();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<CreateCouponDto>({
    initialValues: {
      couponCode: '',
      couponType: CouponType.SINGLE_USE,
      benefitPeriod: BenefitPeriod.TWO_MONTH,
    },
    validationSchema: Yup.object({
      couponCode: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const coupon = await postCoupon(values).unwrap();

      message.success('새 쿠폰을 등록했습니다');
      navigate(Path.COUPON);
    },
  });

  const onChange = (key: keyof CreateCouponDto) => (e: any) => setFieldValue(key, e);

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="쿠폰 코드" help={errors.couponCode}>
        <Input value={values.couponCode} name="couponCode" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="사용횟수">
        <Select value={values.couponType} onSelect={onChange('couponType')}>
          <Select.Option key={CouponType.MULTI_USE} value={CouponType.MULTI_USE}>
            다회용
          </Select.Option>
          <Select.Option key={CouponType.SINGLE_USE} value={CouponType.SINGLE_USE}>
            일회용
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="기간">
        <Select value={values.benefitPeriod} onSelect={onChange('benefitPeriod')}>
          <Select.Option key={BenefitPeriod.ONE_MONTH} value={BenefitPeriod.ONE_MONTH}>
            한달
          </Select.Option>
          <Select.Option key={BenefitPeriod.TWO_MONTH} value={BenefitPeriod.TWO_MONTH}>
            두달
          </Select.Option>
        </Select>
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            등록
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default CouponCreate;
