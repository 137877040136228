import { createApi } from '@reduxjs/toolkit/query/react';

import Coupon from '~/classes/Coupon';
import { CreateCouponDto } from '~/classes/Dto/CreateCouponDto';
import { SearchCouponDto } from '~/classes/Dto/SearchCouponDto';
import Pagination from '~/classes/Pagination';
import { axiosBaseQuery } from '~/helper/axios';

export const couponApi = createApi({
  reducerPath: 'couponApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getCoupons: builder.query<Pagination<Coupon>, SearchCouponDto>({
      query: (params) => ({ url: '/coupon', method: 'get', params }),
      transformResponse: (data: Pagination<Coupon>) => new Pagination(data, Coupon),
    }),
    postCoupon: builder.query<Coupon, CreateCouponDto>({
      query: (data) => ({ url: '/coupon', method: 'post', data }),
      transformResponse: (data: Coupon) => new Coupon(data),
    }),
  }),
});

export const { useGetCouponsQuery, useLazyPostCouponQuery } = couponApi;
