import { Button, DatePicker, Form, Input, message, Select, Switch, TimePicker } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLazyPostBoardQuery } from '~/api/BoardAPI';
import { BoardPriority, BoardType } from '~/classes/Board';
import CreateBoardDto from '~/classes/Dto/CreateBoardDto';
import { FlexEndContainer } from '~/components/Styled/Containers';
import { TextArea } from '~/components/Texts';
import Path from '~/constants/Path';
import { useSelector } from '~/hooks/useRedux';

const BoardCreate = () => {
  const navigate = useNavigate();
  const letterBoxs = useSelector((state) => state.letter.letterBoxs);
  const [postBoard] = useLazyPostBoardQuery();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<CreateBoardDto>({
    initialValues: {
      boardType: BoardType.NOTICE,
      title: '',
      preview: '',
      content: '',
      weeklyTopicWriterName: '',
      isPublish: false,
      publishedAt: new Date(),
      boardPriority: BoardPriority.MEDIUM,
      letterBoxId: undefined,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      content: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const { isSuccess, isError } = await postBoard(values);

      if (isSuccess) {
        message.success('새 글을 등록했습니다');

        switch (values.boardType) {
          case BoardType.NOTICE:
            navigate(Path.NOTICE);

            break;
          case BoardType.BEST_LETTER:
            navigate(Path.BEST_LETTER);

            break;
          case BoardType.WEEKLY_TOPIC:
            navigate(Path.WEEKLY_TOPIC);

            break;
        }
      }

      if (isError) {
        message.error('새 글 등록에 실패했습니다');
      }
    },
  });

  const onChange = (key: keyof CreateBoardDto) => (e: any) => setFieldValue(key, e);

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="타입">
        <Select value={values.boardType} onSelect={onChange('boardType')}>
          <Select.Option key={BoardType.NOTICE} value={BoardType.NOTICE}>
            공지사항
          </Select.Option>
          <Select.Option key={BoardType.BEST_LETTER} value={BoardType.BEST_LETTER}>
            모두의 편지
          </Select.Option>
          <Select.Option key={BoardType.WEEKLY_TOPIC} value={BoardType.WEEKLY_TOPIC}>
            금주의 주제
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="제목" help={errors.title}>
        <Input value={values.title} name="title" onChange={handleChange} />
      </Form.Item>

      {values.boardType === BoardType.WEEKLY_TOPIC && (
        <Form.Item label="작가명">
          <Input
            value={values.weeklyTopicWriterName}
            name="weeklyTopicWriterName"
            onChange={handleChange}
          />
        </Form.Item>
      )}

      <Form.Item label="미리보기" help={errors.preview}>
        <TextArea
          value={values.preview}
          name="preview"
          onChange={handleChange}
          rows={4}
          minLength={0}
        />
      </Form.Item>

      <Form.Item label="본문" help={errors.content}>
        <TextArea value={values.content} name="content" onChange={handleChange} rows={10} />
      </Form.Item>

      <Form.Item label="공개 여부" valuePropName="checked">
        <Switch checked={values.isPublish} onChange={onChange('isPublish')} />
      </Form.Item>

      <Form.Item label="공개 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.publishedAt)}
          onChange={onChange('publishedAt')}
        />
        <TimePicker
          allowClear={false}
          value={moment(values.publishedAt)}
          onChange={onChange('publishedAt')}
        />
      </Form.Item>

      <Form.Item label="우선순위">
        <Select value={values.boardPriority} onSelect={onChange('boardPriority')}>
          <Select.Option key={BoardPriority.HIGH} value={BoardPriority.HIGH}>
            HIGH
          </Select.Option>
          <Select.Option key={BoardPriority.MEDIUM} value={BoardPriority.MEDIUM}>
            MEDIUM
          </Select.Option>
          <Select.Option key={BoardPriority.LOW} value={BoardPriority.LOW}>
            LOW
          </Select.Option>
        </Select>
      </Form.Item>

      {values.boardType !== BoardType.NOTICE && (
        <Form.Item label="주차">
          <Select value={values.letterBoxId} onSelect={onChange('letterBoxId')}>
            {letterBoxs
              .map((letterBox) => (
                <Select.Option key={letterBox.id} value={letterBox.id}>
                  {letterBox.weeks}
                </Select.Option>
              ))
              .reverse()}
          </Select>
        </Form.Item>
      )}

      <FlexEndContainer>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={values.boardType !== BoardType.NOTICE && values.letterBoxId === undefined}
          >
            등록
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default BoardCreate;
