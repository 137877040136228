import { message } from 'antd';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Path from './constants/Path';
import { useDispatch, useSelector } from './hooks/useRedux';
import { load } from './reducers/auth';
import { letterBoxs } from './reducers/letter';
import BestLetter from './routes/BestLetter';
import BestLetterConfirm from './routes/BestLetterConfirm';
import Block from './routes/Block';
import BoardCreate from './routes/BoardCreate';
import BoardDetail from './routes/BoardDetail';
import Confirm from './routes/Confirm';
import Coupon from './routes/Coupon';
import CouponCreate from './routes/CouponCreate';
import DirectNotification from './routes/DirectNotification';
import Home from './routes/Home';
import LetterConfirm from './routes/LetterConfirm';
import LetterScreening from './routes/LetterScreening';
import Letters from './routes/Letters';
import Notice from './routes/Notice';
import Screening from './routes/Screening';
import Season from './routes/Season';
import SeasonCreate from './routes/SeasonCreate';
import SeasonDetail from './routes/SeasonDetail';
import SignIn from './routes/SignIn';
import User from './routes/User';
import UserDetail from './routes/UserDetail';
import UserDetailPayment from './routes/UserDetailPayment';
import UserDetailPaymentCreate from './routes/UserDetailPaymentCreate';
import WeeklyTopic from './routes/WeeklyTopic';

message.config({
  duration: 2,
  maxCount: 3,
  rtl: true,
});

const Router = () => {
  const dispatch = useDispatch();

  const isTokenLoaded = useSelector((state) => state.auth.isTokenLoaded);
  const isSignIn = useSelector((state) => state.auth.isSignIn);
  const serviceType = useSelector((state) => state.letter.serviceType);

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);

  useEffect(() => {
    dispatch(letterBoxs(serviceType));
  }, [dispatch, serviceType]);

  if (!isTokenLoaded) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <ProtectedRoute isSignIn={isSignIn} withLayout={true} redirectPath={Path.SIGN_IN} />
          }
        >
          <Route path={Path.HOME} element={<Home />} />

          <Route path={Path.LETTERS} element={<Letters />} />

          <Route path={Path.BEST_LETTER} element={<BestLetter />} />
          <Route path={Path.NOTICE} element={<Notice />} />
          <Route path={Path.WEEKLY_TOPIC} element={<WeeklyTopic />} />

          <Route path={Path.SCREENING} element={<Screening />} />
          <Route path={Path.CONFIRM} element={<Confirm />} />
          <Route path={Path.BEST_LETTER_CONFIRM} element={<BestLetterConfirm />} />

          <Route path={Path.USER} element={<User />} />
          <Route path={Path.COUPON} element={<Coupon />} />
          <Route path={Path.COUPON_CREATE} element={<CouponCreate />} />
          <Route path={Path.BLOCK} element={<Block />} />
          <Route path={Path.DIRECT_NOTIFICATION} element={<DirectNotification />} />

          <Route path={Path.SEASON} element={<Season />} />
          <Route path={Path.SEASON_CREATE} element={<SeasonCreate />} />
          <Route path={Path.SEASON_DETAIL} element={<SeasonDetail />} />

          <Route path={Path.BOARD_CREATE} element={<BoardCreate />} />
          <Route path={Path.BOARD_DETAIL} element={<BoardDetail />} />
          <Route path={Path.USER_DETAIL} element={<UserDetail />} />
          <Route path={Path.USER_DETAIL_PAYMENT} element={<UserDetailPayment />} />
          <Route path={Path.USER_DETAIL_PAYMENT_CREATE} element={<UserDetailPaymentCreate />} />

          <Route path={Path.LETTER_SCREENING} element={<LetterScreening />} />
          <Route path={Path.LETTER_CONFIRM} element={<LetterConfirm />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isSignIn={!isSignIn} withLayout={false} redirectPath={Path.HOME} />
          }
        >
          <Route path={Path.SIGN_IN} element={<SignIn />} />

          <Route path={'/*'} element={<Navigate to={Path.SIGN_IN} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
