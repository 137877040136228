import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '~/helper/axios';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    postNotificationTest: builder.mutation<{ success: boolean }, { title: string; body: string }>({
      query: (data) => ({ url: '/notification/direct-test', method: 'post', data }),
    }),
    postNotification: builder.mutation<{ success: boolean }, { title: string; body: string }>({
      query: (data) => ({ url: '/notification/direct', method: 'post', data }),
    }),
  }),
});

export const { usePostNotificationTestMutation, usePostNotificationMutation } = notificationApi;
