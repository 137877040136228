import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetBlockListQuery } from '~/api/BlockAPI';
import { BlockList } from '~/classes/BlockList';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import usePaginationRequest from '~/hooks/usePaginationRequest';

const BlockPage = () => {
  const navigate = useNavigate();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const { data } = useGetBlockListQuery({ ...pagination });

  const columns = useMemo<ColumnsType<BlockList>>(
    () => [
      { title: 'Block ID', dataIndex: 'id' },
      { title: '신고자 id', dataIndex: 'userId' },
      {
        title: '신고자 Nickname',
        dataIndex: 'user',
        render: (user) => <>{user?.nickname}</>,
      },
      { title: 'Category', dataIndex: 'category' },
      { title: 'Comment', dataIndex: 'content' },
      {
        title: '블랙 id',
        dataIndex: 'blockUserId',
      },
      {
        title: '블랙 Nickname',
        dataIndex: 'blockUser',
        render: (blockUser) => <>{blockUser?.nickname}</>,
      },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        {/*  */}
        {/*  */}
        {/*  */}
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(board) => ({
          onClick: () => {
            navigate(`/user/${board.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default BlockPage;
