import Letter from './Letter';
import User from './User';

export class BlockList {
  id: number;
  userId: number;
  user?: User;
  blockUserId: number;
  blockUser?: User;
  category: string;
  content: string;
  letterId: number;
  letter?: Letter;
  createdAt: Date;
  updatedAt: Date;

  constructor(blockList: BlockList) {
    this.id = blockList.id;
    this.userId = blockList.userId;
    this.user = blockList.user && new User(blockList.user);
    this.blockUserId = blockList.blockUserId;
    this.blockUser = blockList.blockUser && new User(blockList.blockUser);
    this.category = blockList.category;
    this.content = blockList.content;
    this.letterId = blockList.letterId;
    this.letter = blockList.letter && new Letter(blockList.letter);
    this.createdAt = blockList.createdAt;
    this.updatedAt = blockList.updatedAt;
  }
}
