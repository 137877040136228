import User from './User';

export enum Role {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  USER = 'USER',
}

export class UserRole {
  id: number;
  userId: number;
  user?: User;
  role: Role;
  createdAt: Date;
  updatedAt: Date;

  constructor(userRole: UserRole) {
    this.id = userRole.id;
    this.userId = userRole.userId;
    this.user = userRole.user && new User(userRole.user);
    this.role = userRole.role;
    this.createdAt = new Date(userRole.createdAt);
    this.updatedAt = new Date(userRole.updatedAt);
  }
}
