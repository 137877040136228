import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
 
  .ant-layout-content {
    min-height: 100vh;
    padding: 30px;
  }

  section.ant-layout li.ant-menu-item,
  section.ant-layout .ant-menu-inline .ant-menu-item:not(:last-child)
  {
    margin-top: 0;
    margin-bottom: 0;
  }

  .cursor {
    cursor: pointer;
  }

  .ant-form-item-control-input-content .ant-input-disabled {
    color: rgba(0, 0, 0, 0.7);
    background-color: #ffffff33;
  }
`;
