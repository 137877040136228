import { CouponHistory } from './CouponHistory';

export enum CouponType {
  SINGLE_USE = 'SINGLE_USE',
  MULTI_USE = 'MULTI_USE',
}

export enum BenefitPeriod {
  ONE_MONTH = 'ONE_MONTH',
  TWO_MONTH = 'TWO_MONTH',
}

export default class Coupon {
  id: number;
  couponCode: string;
  couponType: CouponType;
  used: boolean;
  benefitPeriod: BenefitPeriod;
  couponHistories: CouponHistory[];
  createdAt: Date;
  updatedAt: Date;

  constructor(coupon: Coupon) {
    this.id = coupon.id;
    this.couponCode = coupon.couponCode;
    this.couponType = coupon.couponType;
    this.used = coupon.used;
    this.benefitPeriod = coupon.benefitPeriod;
    this.couponHistories =
      coupon.couponHistories && coupon.couponHistories.map((history) => new CouponHistory(history));
    this.createdAt = new Date(coupon.createdAt);
    this.updatedAt = new Date(coupon.updatedAt);
  }
}
