import { Button, DatePicker, Form, Input, Switch, TimePicker, message } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { usePostPaymentMutation } from '~/api/PaymentAPI';
import CreatePaymentDto from '~/classes/Dto/CreatePaymentDto';
import { Param } from '~/classes/Param';
import { FlexEndContainer } from '~/components/Styled/Containers';

const BoardCreate = () => {
  const navigate = useNavigate();
  const { userId } = useParams<Param>();
  const [postPayment] = usePostPaymentMutation();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<CreatePaymentDto>(
    {
      initialValues: {
        userId: +userId!,
        orderId: 0,
        orderCode: '',
        isTrial: false,
        expiredAt: new Date(),
      },
      validationSchema: Yup.object({
        orderId: Yup.string().required(),
        orderCode: Yup.string().required(),
      }),
      onSubmit: async (values) => {
        await postPayment(values);

        message.success('새 결제정보를 등록했습니다.');

        navigate(`/user/${userId}/payment`);
      },
    },
  );

  const onChange = (key: keyof CreatePaymentDto) => (e: any) => setFieldValue(key, e);

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="orderId" help={errors.orderId}>
        <Input value={values.orderId} name="orderId" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="orderCode" help={errors.orderCode}>
        <Input value={values.orderCode} name="orderCode" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="isTrial" valuePropName="checked">
        <Switch checked={values.isTrial} onChange={onChange('isTrial')} />
      </Form.Item>

      <Form.Item label="expiredAt" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.expiredAt)}
          onChange={onChange('expiredAt')}
        />
        <TimePicker
          allowClear={false}
          value={moment(values.expiredAt)}
          onChange={onChange('expiredAt')}
        />
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            등록
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default BoardCreate;
