import { Button, Form, Input, Space } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  usePostNotificationMutation,
  usePostNotificationTestMutation,
} from '~/api/NotificationAPI';
import { FlexEndContainer } from '~/components/Styled/Containers';

const DirectNotification = () => {
  const [isSendTest, setIsSendTest] = useState(false);
  const [postNotificationTest] = usePostNotificationTestMutation();
  const [postNotification] = usePostNotificationMutation();

  const { values, errors, handleChange, handleSubmit } = useFormik<{
    title: string;
    body: string;
  }>({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required().min(1),
      body: Yup.string().required().min(1),
    }),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleNotificationTest = async () => {
    setIsSendTest(true);
    await postNotificationTest(values);
  };

  const handleNotification = async () => {
    await postNotification(values);
  };

  useEffect(() => {
    setIsSendTest(false);
  }, [values]);

  return (
    <>
      <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
        <Form.Item label="제목" help={errors.title}>
          <Input value={values.title} name="title" onChange={handleChange} />
        </Form.Item>

        <Form.Item label="내용" help={errors.body}>
          <Input value={values.body} name="body" onChange={handleChange} />
        </Form.Item>
      </Form>

      <FlexEndContainer>
        <Space>
          <Button
            type="primary"
            onClick={handleNotificationTest}
            disabled={
              Object.values(errors).length !== 0 ||
              values.title.length === 0 ||
              values.body.length === 0
            }
          >
            관리자 발송
          </Button>

          <Button type="primary" onClick={handleNotification} disabled={!isSendTest}>
            전체 발송
          </Button>
        </Space>
      </FlexEndContainer>
    </>
  );
};

export default DirectNotification;
