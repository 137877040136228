import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Path from '~/constants/Path';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(Path.LETTERS);
  }, [navigate]);

  return <>Home!</>;
};

export default Home;
