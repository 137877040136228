export class Season {
  id: number;
  author: string;
  title: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(season: Season) {
    this.id = season.id;
    this.author = season.author;
    this.title = season.title;
    this.startDate = new Date(season.startDate);
    this.endDate = new Date(season.endDate);
    this.createdAt = new Date(season.createdAt);
    this.updatedAt = new Date(season.updatedAt);
  }
}
