import { Input, Select, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetLetterListQuery } from '~/api/LetterAPI';
import { SearchLetterDto } from '~/classes/Dto/SearchLetterDto';
import Letter, { LetterStatus, LetterStatusLabel } from '~/classes/Letter';
import SelectWeek from '~/components/SelectWeek';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useSelector } from '~/hooks/useRedux';
import useSearch from '~/hooks/useSearch';

const Screening = () => {
  const navigate = useNavigate();

  const serviceType = useSelector((state) => state.letter.serviceType);

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchLetterDto>('screeningSearch', {
    serviceType,
    letterStatus: LetterStatus.SUBMITTED,
  });

  const { data } = useGetLetterListQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Letter>>(
    () => [
      { title: 'Letter ID', dataIndex: 'id' },
      {
        title: '주차',
        dataIndex: 'letterBox',
        render: (letterBox) => <>{letterBox.weeks}</>,
      },
      {
        title: '새편지/답장',
        dataIndex: 'isReply',
        render: (isReply) =>
          isReply ? <Tag color="processing">답장</Tag> : <Tag color="success">새편지</Tag>,
      },
      {
        title: '상태',
        dataIndex: 'letterStatus',
        render: (letterStatus, letter) => (
          <>
            {LetterStatusLabel[letterStatus]}{' '}
            {letter.letterDeleteStatus && (
              <Tooltip title={letter.letterDeleteStatus}>
                <Tag color="error">Deleted</Tag>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: '편지 본문',
        dataIndex: 'originContent',
        render: (originContent: string) => <>{originContent.slice(0, 25)}</>,
      },
      {
        title: '닉네임 (ID)',
        dataIndex: 'senderUser',
        render: (senderUser) => (
          <>
            {senderUser?.nickname} <Tag>{senderUser?.id}</Tag>
          </>
        ),
      },
      { title: '문제 내용', dataIndex: 'letterFilteredReason' },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <Space>
          <SelectWeek
            value={search.weeks}
            onChange={(weeks) => setSearch((search) => ({ ...search, weeks }))}
          />

          <Select
            allowClear
            value={search.letterStatus}
            placeholder="상태"
            style={{ width: 150 }}
            onChange={(letterStatus) =>
              setSearch((search) => ({
                ...search,
                letterStatus,
                deleted: letterStatus === LetterStatus.SCREENING_OPERATOR_FAILURE,
              }))
            }
            dropdownMatchSelectWidth={false}
          >
            <Select.Option key={LetterStatus.SUBMITTED} value={LetterStatus.SUBMITTED}>
              {LetterStatusLabel[LetterStatus.SUBMITTED]}
            </Select.Option>
            <Select.Option
              key={LetterStatus.SCREENING_OPERATOR_COMPLETE}
              value={LetterStatus.SCREENING_OPERATOR_COMPLETE}
            >
              {LetterStatusLabel[LetterStatus.SCREENING_OPERATOR_COMPLETE]}
            </Select.Option>
            <Select.Option
              key={LetterStatus.SCREENING_OPERATOR_FAILURE}
              value={LetterStatus.SCREENING_OPERATOR_FAILURE}
            >
              {LetterStatusLabel[LetterStatus.SCREENING_OPERATOR_FAILURE]}
            </Select.Option>
          </Select>
        </Space>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="내용 / 닉네임"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(letter) => ({
          onClick: () => {
            navigate(`/letter-screening/${letter.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default Screening;
