import { configureStore } from '@reduxjs/toolkit';

import { blockApi } from '~/api/BlockAPI';
import { boardApi } from '~/api/BoardAPI';
import { couponApi } from '~/api/CouponAPI';
import { letterApi } from '~/api/LetterAPI';
import { notificationApi } from '~/api/NotificationAPI';
import { paymentApi } from '~/api/PaymentAPI';
import { seasonApi } from '~/api/SeasonAPI';
import { userApi } from '~/api/UserAPI';

import authReducer from './auth';
import letterReducer from './letter';
import userReducer from './user';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    letter: letterReducer,
    [userApi.reducerPath]: userApi.reducer,
    [letterApi.reducerPath]: letterApi.reducer,
    [boardApi.reducerPath]: boardApi.reducer,
    [blockApi.reducerPath]: blockApi.reducer,
    [seasonApi.reducerPath]: seasonApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      letterApi.middleware,
      boardApi.middleware,
      blockApi.middleware,
      seasonApi.middleware,
      notificationApi.middleware,
      paymentApi.middleware,
      couponApi.middleware,
    ),
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
