import { Button, DatePicker, Form, Input, message } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetSeasonQuery, useLazyPatchSeasonQuery } from '~/api/SeasonAPI';
import { UpdateSeasonDto } from '~/classes/Dto/UpdateSeasonDto';
import { Param } from '~/classes/Param';
import { FlexEndContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';

const SeasonDetail = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams<Param>();
  const { data: season } = useGetSeasonQuery(+seasonId!);

  const [patchSeason] = useLazyPatchSeasonQuery();

  const { values, errors, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik<UpdateSeasonDto>({
      initialValues: {
        id: +seasonId!,
        author: '',
        title: '',
        startDate: new Date(),
        endDate: new Date(),
      },
      validationSchema: Yup.object({
        author: Yup.string().required(),
        title: Yup.string().required(),
      }),
      onSubmit: async (values) => {
        const { isSuccess, isError } = await patchSeason(values);

        if (isSuccess) {
          message.success('시즌을 업데이트했습니다.');
          navigate(Path.SEASON);
        }

        if (isError) {
          message.error('시즌 업데이트에 실패했습니다.');
        }
      },
    });

  const onChange = (key: keyof UpdateSeasonDto) => (e: any) => setFieldValue(key, e);

  useEffect(() => {
    if (season) {
      setValues(season);
    }
  }, [season, setValues]);

  if (!season) {
    return <></>;
  }

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="작가" help={errors.author}>
        <Input value={values.author} name="author" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="제목" help={errors.title}>
        <Input value={values.title} name="title" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="시작 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.startDate)}
          onChange={onChange('startDate')}
        />
      </Form.Item>

      <Form.Item label="시작 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.endDate)}
          onChange={onChange('endDate')}
        />
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            등록
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default SeasonDetail;
