export default {
  // without authentication
  SIGN_IN: '/sign-in',

  // with authentication
  HOME: '/',

  LETTERS: '/letters',

  NOTICE: '/notice',
  BEST_LETTER: '/best-letter',
  WEEKLY_TOPIC: '/weekly-topic',

  SCREENING: '/screening',
  CONFIRM: '/confirm',
  BEST_LETTER_CONFIRM: '/best-letter-confirm',

  USER: '/user',
  BLOCK: '/block',
  DIRECT_NOTIFICATION: '/direct-notification',
  COUPON: '/coupon',
  COUPON_CREATE: '/coupon/create',
  SEASON: '/season',
  SEASON_CREATE: '/season/create',
  SEASON_DETAIL: '/season/:seasonId',

  BOARD_CREATE: '/board/create',

  // Board, User는 서브 페이지 체크를 상세하게 하기 위한 설계가 필요함
  BOARD_DETAIL: '/board/:boardId',
  USER_DETAIL: '/user/:userId',
  USER_DETAIL_PAYMENT: '/user/:userId/payment',
  USER_DETAIL_PAYMENT_CREATE: '/user/:userId/payment/create',

  LETTER_SCREENING: '/letter-screening/:letterId',
  LETTER_CONFIRM: '/letter-confirm/:letterId',
};
