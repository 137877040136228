import { Button, Input, Space, Switch, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetUserListQuery } from '~/api/UserAPI';
import { SearchUserDto } from '~/classes/Dto/SearchUserDto';
import User from '~/classes/User';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useSearch from '~/hooks/useSearch';

const UserPage = () => {
  const navigate = useNavigate();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchUserDto>('userSearch', { deleted: false });
  const { data } = useGetUserListQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<User>>(
    () => [
      { title: 'User ID', dataIndex: 'id' },
      { title: 'Email', dataIndex: 'email' },
      { title: 'Name', dataIndex: 'realname' },
      { title: 'Nickname', dataIndex: 'nickname' },
      {
        title: 'IsPaid',
        dataIndex: 'isPaid',
        render: (isPaid: boolean) => (isPaid ? <Tag color="success">PAID</Tag> : <></>),
      },
      { title: 'Comment', dataIndex: 'comment' },
      { title: 'SteppayId', dataIndex: 'steppayId' },
      { title: 'Deleted', dataIndex: 'deletedReason' },
      {
        title: 'Payment',
        dataIndex: 'id',
        onCell: ({ id }) => ({
          onClick: (e) => {
            e.stopPropagation();
            navigate(`/user/${id}/payment`);
          },
        }),
        render: (id: number) => <Button style={{ zIndex: 10 }}>결제정보</Button>,
      },
    ],
    [navigate],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <Space>
          <Switch
            checked={search.deleted}
            onChange={(deleted) => setSearch((search) => ({ ...search, deleted }))}
            checkedChildren={'삭제'}
            unCheckedChildren={'삭제'}
          />
        </Space>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="닉네임 / 이메일 / 실명 / steppayId"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
            style={{ width: 300 }}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(user) => ({
          onClick: () => {
            navigate(`/user/${user.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default UserPage;
