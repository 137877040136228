import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';

const useSearch = <FilterType extends Object>(
  name: string,
  initialValue: FilterType,
): [
  FilterType,
  (search: FilterType | ((prevState: FilterType) => FilterType)) => void,
  () => void,
] => {
  const [local, setLocal] = useLocalStorage(name, JSON.stringify(initialValue));
  const [search, setSearch] = useState<FilterType>(JSON.parse(local));

  const handleSearch = useCallback(
    (state: FilterType | ((prevState: FilterType) => FilterType)) => {
      setSearch((prev) => (typeof state === 'function' ? state(prev) : state));
    },
    [],
  );

  const handleReset = useCallback(() => {
    setSearch(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const searchString = JSON.stringify(search);

    if (local !== searchString) {
      setLocal(JSON.stringify(search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, local]);

  return [search, handleSearch, handleReset];
};

export default useSearch;
