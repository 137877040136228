import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { useGetSeasonListQuery } from '~/api/SeasonAPI';
import { Season } from '~/classes/Season';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';
import usePaginationRequest from '~/hooks/usePaginationRequest';

const SeasonPage = () => {
  const navigate = useNavigate();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const { data } = useGetSeasonListQuery({ ...pagination });

  const columns = useMemo<ColumnsType<Season>>(
    () => [
      { title: 'Block ID', dataIndex: 'id' },
      { title: 'author', dataIndex: 'author' },
      { title: 'title', dataIndex: 'title' },
      {
        title: 'startDate',
        dataIndex: 'startDate',
        render: (startDate: Date) => <>{startDate.toLocaleDateString()}</>,
      },
      {
        title: 'endDate',
        dataIndex: 'endDate',
        render: (endDate: Date) => <>{endDate.toLocaleDateString()}</>,
      },
      {
        title: 'createdAt',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => <>{createdAt.toLocaleString()}</>,
      },
      {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => <>{updatedAt.toLocaleString()}</>,
      },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        {/*  */}
        {/*  */}
        {/*  */}
        <div></div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(Path.SEASON_CREATE)}
        />
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(season) => ({
          onClick: () => {
            navigate(`/season/${season.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default SeasonPage;
