import Coupon from './Coupon';
import User from './User';

export class CouponHistory {
  id: number;
  useUserId: number;
  useUser?: User;
  couponId: number;
  coupon?: Coupon;
  createdAt: Date;

  constructor(couponHistory: CouponHistory) {
    this.id = couponHistory.id;
    this.useUserId = couponHistory.useUserId;
    this.useUser = couponHistory.useUser && new User(couponHistory.useUser);
    this.couponId = couponHistory.couponId;
    this.coupon = couponHistory.coupon && new Coupon(couponHistory.coupon);
    this.createdAt = new Date(couponHistory.createdAt);
  }
}
