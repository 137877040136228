import { Button, DatePicker, Form, Input, message } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLazyPostSeasonQuery } from '~/api/SeasonAPI';
import { CreateSeasonDto } from '~/classes/Dto/CreateSeasonDto';
import { FlexEndContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';

const SeasonCreate = () => {
  const navigate = useNavigate();
  const [postSeason] = useLazyPostSeasonQuery();

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<CreateSeasonDto>({
    initialValues: {
      author: '',
      title: '',
      startDate: new Date(),
      endDate: new Date(),
    },
    validationSchema: Yup.object({
      author: Yup.string().required(),
      title: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const { isSuccess, isError } = await postSeason(values);

      if (isSuccess) {
        message.success('새 시즌을 등록했습니다');
        navigate(Path.SEASON);
      }

      if (isError) {
        message.error('새 시즌 등록에 실패했습니다');
      }
    },
  });

  const onChange = (key: keyof CreateSeasonDto) => (e: any) => setFieldValue(key, e);

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="작가" help={errors.author}>
        <Input value={values.author} name="author" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="제목" help={errors.title}>
        <Input value={values.title} name="title" onChange={handleChange} />
      </Form.Item>

      <Form.Item label="시작 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.startDate)}
          onChange={onChange('startDate')}
        />
      </Form.Item>

      <Form.Item label="시작 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.endDate)}
          onChange={onChange('endDate')}
        />
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            등록
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default SeasonCreate;
