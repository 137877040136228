import { Button, Space, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { useGetPaymentsByUserIdQuery } from '~/api/PaymentAPI';
import { SearchPaymentDto } from '~/classes/Dto/SearchPaymentDto';
import { Param } from '~/classes/Param';
import { Payment } from '~/classes/Payment';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useSearch from '~/hooks/useSearch';

const UserDetailPayment = () => {
  const navigate = useNavigate();
  const { userId } = useParams<Param>();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchPaymentDto>('searchPayment', {
    userId: +userId!,
  });
  const { data } = useGetPaymentsByUserIdQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Payment>>(
    () => [
      { title: 'Payment ID', dataIndex: 'id' },
      { title: 'Order Id', dataIndex: 'orderId' },
      { title: 'Order Code', dataIndex: 'orderCode' },
      { title: 'Payment Status', dataIndex: 'paymentStatus' },
      {
        title: 'Is Expired',
        dataIndex: 'isExpired',
        render: (isExpired: boolean) =>
          isExpired ? <Tag color="default">만료</Tag> : <Tag color="success">진행</Tag>,
      },
      {
        title: 'expiredAt',
        dataIndex: 'expiredAt',
        render: (expiredAt: Date) => <>{expiredAt.toLocaleString()}</>,
      },
      {
        title: 'createdAt',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => <>{createdAt.toLocaleString()}</>,
      },
      {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => <>{updatedAt.toLocaleString()}</>,
      },
    ],
    [],
  );

  console.log(data);

  return (
    <>
      <SpaceBetweenContainer>
        <div></div>

        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(Path.USER_DETAIL_PAYMENT_CREATE.replace(':userId', userId!))}
          />
        </Space>
      </SpaceBetweenContainer>
      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default UserDetailPayment;
