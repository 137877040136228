import {
  Button,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Select,
  Switch,
  Tag,
  TimePicker,
} from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetLetterQuery, useLazyPatchLetterConfirmQuery } from '~/api/LetterAPI';
import { UpdateLetterDto } from '~/classes/Dto/UpdateLetterDto';
import Letter, {
  AmountOfRecipientLabel,
  LetterDeleteStatus,
  LetterStatus,
  LetterStatusLabel,
} from '~/classes/Letter';
import { Param } from '~/classes/Param';
import { FlexEndContainer } from '~/components/Styled/Containers';
import { TextArea } from '~/components/Texts';
import Path from '~/constants/Path';

const LetterConfirm = () => {
  const navigate = useNavigate();
  const { letterId } = useParams<Param>();

  const { data: letter } = useGetLetterQuery(+letterId!);
  const [patchLetterConfirm] = useLazyPatchLetterConfirmQuery();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: letter || ({} as any),
    validationSchema: Yup.object({
      screenedContent: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      console.log(values);

      const updateLetterDto = new UpdateLetterDto(values);

      const { isSuccess, isError } = await patchLetterConfirm(updateLetterDto);

      if (isSuccess) {
        message.success('최종 검수를 완료했습니다');
        navigate(Path.CONFIRM);
      }

      if (isError) {
        message.error('최종 검수에 실패했습니다');
      }
    },
  });

  const onChange = (key: keyof Letter) => (e: any) => setFieldValue(key, e);

  useEffect(() => {
    if (letter) {
      setValues(letter);
    }
  }, [letter, setValues]);

  if (!letter) {
    return <></>;
  }

  return (
    <Form onFinish={handleSubmit}>
      <Descriptions title={'Letter 최종 검수'} bordered>
        <Descriptions.Item label="Letter ID">{letter.id}</Descriptions.Item>

        <Descriptions.Item label="주차">{letter.letterBox?.weeks}</Descriptions.Item>

        <Descriptions.Item label="작성인 닉네임">
          {letter.senderUser?.nickname} <Tag>{letter.senderUserId}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="상태">{LetterStatusLabel[letter.letterStatus]}</Descriptions.Item>

        <Descriptions.Item label="새편지/답장">
          {letter.isReply ? <Tag color="processing">답장</Tag> : <Tag color="success">새편지</Tag>}
        </Descriptions.Item>

        <Descriptions.Item label="소수/다수">
          <Input disabled value={AmountOfRecipientLabel[letter.amountOfRecipient]} />
        </Descriptions.Item>

        <Descriptions.Item label="수신 사용자">
          {letter.letterRecipients?.length} 명
          <Tag>
            {letter.letterRecipients
              ?.map((letterRecipient) => letterRecipient.recipientUserId)
              .join(',')}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="모두의 편지 동의">
          <Switch disabled checked={letter.agreeOfBestLetter} />
        </Descriptions.Item>

        <Descriptions.Item label="작성 시간">
          <DatePicker disabled value={moment(letter.createdAt)} />
          <TimePicker disabled value={moment(letter.createdAt)} />
        </Descriptions.Item>

        <Descriptions.Item label="편지 본문" span={3}>
          <TextArea disabled value={letter.originContent} rows={4} />
        </Descriptions.Item>

        <Descriptions.Item label="검수" span={3}>
          <TextArea
            value={values.screenedContent}
            rows={8}
            name="screenedContent"
            onChange={handleChange}
          />
        </Descriptions.Item>

        <Descriptions.Item label="모두의 편지 후보">
          <Switch
            disabled={!letter.agreeOfBestLetter}
            checked={values.candidateOfBestLetter}
            onChange={onChange('candidateOfBestLetter')}
          />
        </Descriptions.Item>

        <Descriptions.Item label="삭제 상태">
          <Select
            value={values.letterDeleteStatus}
            onSelect={onChange('letterDeleteStatus')}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: '100px' }}
          >
            <Select.Option value={null}> </Select.Option>
            <Select.Option
              key={LetterDeleteStatus.ADMIN_DELETE}
              value={LetterDeleteStatus.ADMIN_DELETE}
            >
              {LetterDeleteStatus.ADMIN_DELETE}
            </Select.Option>
          </Select>
        </Descriptions.Item>

        <Descriptions.Item label="삭제 이유">
          <Input
            value={values.letterFilteredReason}
            name="letterFilteredReason"
            onChange={handleChange}
          />
        </Descriptions.Item>
      </Descriptions>

      <FlexEndContainer>
        <Form.Item>
          <Button
            disabled={
              letter.letterStatus === LetterStatus.SENT_COMPLETE ||
              (!letter.isReply && letter.letterBox?.isClosed)
            }
            type="primary"
            htmlType="submit"
            danger={!!values.letterDeleteStatus}
          >
            {values.letterDeleteStatus ? '편지 최종 검수 필터' : '편지 최종 검수 완료'}
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default LetterConfirm;
