import { Input } from 'antd';
import { ChangeEventHandler } from 'react';
import { CSSObject } from 'styled-components';

interface TextAreaProps {
  value?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSObject;
  minLength?: number;
}

export const TextArea = ({
  value,
  name,
  onChange,
  rows,
  placeholder,
  disabled = false,
  style,
  minLength = 300,
}: TextAreaProps) => {
  return (
    <Input.TextArea
      value={value}
      showCount={{
        formatter: ({ value, count }) => {
          const lines = (value.match(/\n/g) || []).length;
          return `줄바꿈 ${lines}/32 글자수 ${count}/3000`;
        },
      }}
      minLength={minLength}
      maxLength={3000}
      name={name}
      onChange={onChange}
      rows={rows}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
    />
  );
};
