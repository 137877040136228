import { LetterDeleteStatus } from '../Letter';

export class UpdateLetterDto {
  id: number;

  screenedContent: string;
  letterDeleteStatus: LetterDeleteStatus;
  candidateOfBestLetter: boolean;
  letterFilteredReason?: string;

  constructor(updateLetterDto: UpdateLetterDto) {
    this.id = updateLetterDto.id;

    this.screenedContent = updateLetterDto.screenedContent;
    this.letterDeleteStatus = updateLetterDto.letterDeleteStatus;
    this.candidateOfBestLetter = updateLetterDto.candidateOfBestLetter;
    this.letterFilteredReason = updateLetterDto.letterFilteredReason;
  }
}
