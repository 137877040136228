import User from './User';

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  REFUND = 'REFUND',
  CANCEL = 'CANCEL',
}

export class Payment {
  id: number;
  userId: number;
  user?: User;
  orderId: number;
  orderCode: string;
  paymentStatus: PaymentStatus;
  isExpired: boolean;
  isTrial: boolean;
  expiredAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(payment: Payment) {
    this.id = payment.id;
    this.userId = payment.userId;
    this.user = payment.user && new User(payment.user);
    this.orderId = payment.orderId;
    this.orderCode = payment.orderCode;
    this.paymentStatus = payment.paymentStatus;
    this.isExpired = payment.isExpired;
    this.isTrial = payment.isTrial;
    this.expiredAt = new Date(payment.expiredAt);
    this.createdAt = new Date(payment.createdAt);
    this.updatedAt = new Date(payment.updatedAt);
  }

  getStatus() {
    switch (this.paymentStatus) {
      case PaymentStatus.SUCCESS:
        return '결제';
      case PaymentStatus.CANCEL:
        return '취소';
      default:
        return '환불';
    }
  }
}
