import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation, createSearchParams } from 'react-router-dom';

import PaginationRequest from '~/classes/PaginationRequest';

const usePaginationRequest = (
  page: number = 1,
  limit: number = 10,
): [PaginationRequest, (page: number) => void, (limit: number) => void] => {
  const navigate = useNavigate();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const pageParam = +(searchParams.get('page') || `${page}`);
  const [pagination, setPagination] = useState(new PaginationRequest({ page: pageParam, limit }));

  const setPage = useCallback(
    (page: number) => {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({ page: `${page}` }).toString(),
      });
      setPagination((pagination) => new PaginationRequest({ page, limit: pagination.limit }));
    },
    [location.pathname, navigate],
  );

  const setLimit = useCallback((limit: number) => {
    localStorage.setItem('limit', `${limit}`);
    setPagination((pagination) => new PaginationRequest({ page: pagination.page, limit }));
  }, []);

  useEffect(() => {
    if (+pageParam !== pagination.page) {
      setPagination(
        (pagination) => new PaginationRequest({ page: +pageParam, limit: pagination.limit }),
      );
    }
  }, [pageParam, pagination.page]);

  return [pagination, setPage, setLimit];
};

export default usePaginationRequest;
