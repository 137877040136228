import { Button, Input, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { useGetCouponsQuery } from '~/api/CouponAPI';
import Coupon from '~/classes/Coupon';
import { CouponHistory } from '~/classes/CouponHistory';
import { SearchCouponDto } from '~/classes/Dto/SearchCouponDto';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useSearch from '~/hooks/useSearch';

const CouponPage = () => {
  const navigate = useNavigate();
  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchCouponDto>('couponSearch', {});
  const { data } = useGetCouponsQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Coupon>>(
    () => [
      { title: 'User ID', dataIndex: 'id' },
      { title: 'Coupon Code', dataIndex: 'couponCode' },
      { title: 'Coupon Type', dataIndex: 'couponType' },
      {
        title: '사용여부',
        dataIndex: 'used',
        render: (used) => (used ? <Tag color="success">사용</Tag> : <Tag>미사용</Tag>),
      },
      {
        title: '사용유저',
        dataIndex: 'couponHistories',
        render: (couponHistories: CouponHistory[]) =>
          couponHistories && couponHistories.map((history) => history.useUserId).join(),
      },
      { title: '쿠폰 기간', dataIndex: 'benefitPeriod' },
      {
        title: 'CreatedAt',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => <>{createdAt.toLocaleString()}</>,
      },
      {
        title: 'UpdatedAt',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => <>{updatedAt.toLocaleString()}</>,
      },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <Space>
          <div />
        </Space>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="쿠폰코드"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
            style={{ width: 300 }}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(Path.COUPON_CREATE)}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        // onRow={(user) => ({
        //   onClick: () => {
        //     navigate(`/user/${user.id}`);
        //   },
        // })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default CouponPage;
