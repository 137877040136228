import { Input, Modal, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';

import { useLazyPostBoardBestLetterQuery } from '~/api/BoardAPI';
import { useGetLetterListQuery } from '~/api/LetterAPI';
import { SearchLetterDto } from '~/classes/Dto/SearchLetterDto';
import Letter from '~/classes/Letter';
import SelectWeek from '~/components/SelectWeek';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import { TextArea } from '~/components/Texts';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useSelector } from '~/hooks/useRedux';
import useSearch from '~/hooks/useSearch';

const { confirm } = Modal;

const BestLetterConfirm = () => {
  const serviceType = useSelector((state) => state.letter.serviceType);

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchLetterDto>('bestLetterConfirmSearch', {
    serviceType,
    candidateOfBestLetter: true,
  });

  const { data } = useGetLetterListQuery({ ...search, ...pagination });
  const [postBoardBestLetter] = useLazyPostBoardBestLetterQuery();

  const columns = useMemo<ColumnsType<Letter>>(
    () => [
      { title: 'Letter ID', dataIndex: 'id' },
      {
        title: '주차',
        dataIndex: 'letterBox',
        render: (letterBox) => <>{letterBox.weeks}</>,
      },
      {
        title: '편지 본문',
        dataIndex: 'originContent',
        render: (originContent: string) => <>{originContent.slice(0, 25)}</>,
      },
      {
        title: '닉네임 (ID)',
        dataIndex: 'senderUser',
        render: (senderUser) => (
          <>
            {senderUser?.nickname} <Tag>{senderUser?.id}</Tag>
          </>
        ),
      },
    ],
    [],
  );

  const showConfirm = (letter: Letter) => {
    confirm({
      title: '이 편지를 모두의 편지로 선정하시겠어요?',
      icon: <CheckCircleOutlined />,
      content: (
        <>
          선정된 편지는 모두의 편지 메뉴에 추가됩니다.
          <TextArea value={letter.screenedContent} rows={20} />
        </>
      ),
      onOk() {
        postBoardBestLetter({
          letterId: letter.id,
        });
      },
    });
  };

  return (
    <>
      <SpaceBetweenContainer>
        <Space>
          <SelectWeek
            value={search.weeks}
            onChange={(weeks) => setSearch((search) => ({ ...search, weeks }))}
          />
        </Space>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="내용 / 닉네임"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
          />
        </Space>
      </SpaceBetweenContainer>
      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(letter) => ({
          onClick: () => showConfirm(letter),
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default BestLetterConfirm;
