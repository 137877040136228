import { message } from 'antd';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import UserAPI from '~/api/UserAPI';
import { SignInDto } from '~/classes/Dto/SignInDto';
import Token from '~/classes/Token';
import User, { Gender, ServiceType } from '~/classes/User';
import { Role } from '~/classes/UserRole';
import axios from '~/helper/axios';

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (signInDto: SignInDto, { rejectWithValue }) => {
    try {
      const { user, token } = await UserAPI.signIn(signInDto);

      if (
        !(
          user.userRoles &&
          user.userRoles.some(
            (userRole) => userRole.role === Role.ADMIN || userRole.role === Role.OPERATOR,
          )
        )
      ) {
        message.error('관리자만 접근이 가능합니다');
        throw new Error('Unauthorized Error');
      }

      Token.instance.setStorage(token);
      axios.defaults.headers.common['authorization'] = `Bearer ${Token.instance.accessToken}`;

      return user;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  },
);

export const load = createAsyncThunk('auth/load', async (_, { rejectWithValue }) => {
  try {
    Token.instance.loadTokenFromStorage();

    if (Token.instance.accessToken === '') {
      return Promise.reject(initialState.me);
    }

    const user = await UserAPI.load();

    if (
      !(
        user.userRoles &&
        user.userRoles.some(
          (userRole) => userRole.role === Role.ADMIN || userRole.role === Role.OPERATOR,
        )
      )
    ) {
      message.error('관리자만 접근이 가능합니다');
      throw new Error('Unauthorized Error');
    }

    return user;
  } catch (err) {
    console.log(err);

    Token.instance.removeStorage();
    return rejectWithValue(err.response?.data || err.message);
  }
});

export interface AuthState {
  isTokenLoaded: boolean;
  isSignIn: boolean;
  me: User;
}

const initialState: AuthState = {
  isTokenLoaded: false,
  isSignIn: false,
  me: {
    id: 0,
    email: '',
    realname: '',
    phone: '',
    birthday: '',
    gender: Gender.OTHER,
    nickname: '',
    address: '',
    addressDetail: '',
    zipCode: '',
    isPaid: false,
    agreeMarketing: false,
    serviceType: ServiceType.OFFICIAL,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      state.isSignIn = false;
      state.me = initialState.me;

      Token.instance.removeStorage();
    },
  },
  extraReducers: {
    [signIn.pending.type]: (state, action) => {},
    [signIn.fulfilled.type]: (state, { payload: user }: PayloadAction<User>) => {
      state.me = user;
      state.isSignIn = true;
    },
    [signIn.rejected.type]: (state, action: PayloadAction) => {
      state.me = initialState.me;
      state.isSignIn = false;
    },

    [load.pending.type]: (state, action) => {
      state.isTokenLoaded = false;
    },
    [load.fulfilled.type]: (state, { payload: user }: PayloadAction<User>) => {
      state.isTokenLoaded = true;
      state.me = user;
      state.isSignIn = true;
    },
    [load.rejected.type]: (state) => {
      state.isTokenLoaded = true;
      state.me = initialState.me;
      state.isSignIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const signOut = auth.actions.signOut;

export default auth.reducer;
