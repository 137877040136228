import { createApi } from '@reduxjs/toolkit/query/react';

import CreatePaymentDto from '~/classes/Dto/CreatePaymentDto';
import { SearchPaymentDto } from '~/classes/Dto/SearchPaymentDto';
import Pagination from '~/classes/Pagination';
import { Payment } from '~/classes/Payment';
import { axiosBaseQuery } from '~/helper/axios';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getPaymentsByUserId: builder.query<Pagination<Payment>, SearchPaymentDto>({
      query: (params) => ({ url: '/payment/payments', method: 'get', params }),
      transformResponse: (data: Pagination<Payment>) => new Pagination(data, Payment),
    }),
    postPayment: builder.mutation<{ success: boolean }, CreatePaymentDto>({
      query: (data) => ({ url: '/payment/create-admin', method: 'post', data }),
    }),
  }),
});

export const { useGetPaymentsByUserIdQuery, usePostPaymentMutation } = paymentApi;
