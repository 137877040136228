import { createApi } from '@reduxjs/toolkit/query/react';

import { CreateSeasonDto } from '~/classes/Dto/CreateSeasonDto';
import { UpdateSeasonDto } from '~/classes/Dto/UpdateSeasonDto';
import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import { Season } from '~/classes/Season';
import { axiosBaseQuery } from '~/helper/axios';

export const seasonApi = createApi({
  reducerPath: 'seasonApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getSeason: builder.query<Season, number>({
      query: (seasonId) => ({ url: `/season/${seasonId}`, method: 'get' }),
      transformResponse: (data: Season) => new Season(data),
    }),
    getSeasonList: builder.query<Pagination<Season>, PaginationRequest>({
      query: (params) => ({ url: '/season', method: 'get', params }),
      transformResponse: (data: Pagination<Season>) => new Pagination(data, Season),
    }),
    postSeason: builder.query<void, CreateSeasonDto>({
      query: (data) => ({ url: '/season', method: 'post', data }),
    }),
    patchSeason: builder.query<void, UpdateSeasonDto>({
      query: (data) => ({ url: '/season', method: 'patch', data }),
    }),
  }),
});

export const {
  useGetSeasonQuery,
  useGetSeasonListQuery,
  useLazyPostSeasonQuery,
  useLazyPatchSeasonQuery,
} = seasonApi;
