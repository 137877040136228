import { createApi } from '@reduxjs/toolkit/query/react';

import { Board } from '~/classes/Board';
import CreateBoardDto from '~/classes/Dto/CreateBoardDto';
import { SearchBoardDto } from '~/classes/Dto/SearchBoardDto';
import UpdateBoardDto from '~/classes/Dto/UpdateBoardDto';
import Pagination from '~/classes/Pagination';
import { axiosBaseQuery } from '~/helper/axios';

export const boardApi = createApi({
  reducerPath: 'boardApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getBoardList: builder.query<Pagination<Board>, SearchBoardDto>({
      query: (params) => ({
        url: '/board',
        method: 'get',
        params: { ...params, isPublish: 'null' },
      }),
      transformResponse: (data: Pagination<Board>) => new Pagination(data, Board),
    }),
    getBoard: builder.query<Board, number>({
      query: (id) => ({ url: `/board/${id}`, method: 'get' }),
      transformResponse: (data: Board) => new Board(data),
    }),
    postBoard: builder.query<void, CreateBoardDto>({
      query: (data) => ({ url: '/board', method: 'post', data }),
    }),
    patchBoard: builder.query<void, UpdateBoardDto>({
      query: (data) => ({ url: '/board', method: 'patch', data }),
    }),
    postBoardBestLetter: builder.query<Board, { letterId: number }>({
      query: (data) => ({ url: `/board/best-letter`, method: 'post', data }),
      transformResponse: (data: Board) => new Board(data),
    }),
  }),
});

export const {
  useGetBoardListQuery,
  useGetBoardQuery,
  useLazyPostBoardQuery,
  useLazyPatchBoardQuery,
  useLazyPostBoardBestLetterQuery,
} = boardApi;

export default {};
