import { Input, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetBoardListQuery } from '~/api/BoardAPI';
import { Board, BoardType } from '~/classes/Board';
import { SearchBoardDto } from '~/classes/Dto/SearchBoardDto';
import User from '~/classes/User';
import SelectWeek from '~/components/SelectWeek';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useSearch from '~/hooks/useSearch';

const BestLetter = () => {
  const navigate = useNavigate();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchBoardDto>('bestLetterSearch', {
    boardType: BoardType.BEST_LETTER,
  });
  const { data } = useGetBoardListQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Board>>(
    () => [
      { title: 'Notice ID', dataIndex: 'id' },
      { title: '주차', dataIndex: 'letterBox', render: (letterBox) => <>{letterBox?.weeks}</> },
      { title: '제목', dataIndex: 'title' },
      {
        title: '미리보기',
        dataIndex: 'preview',
        render: (preview: string) => <>{preview?.slice(0, 25)}</>,
      },
      {
        title: '본문',
        dataIndex: 'content',
        render: (content: string) => <>{content.slice(0, 25)}</>,
      },
      {
        title: '닉네임',
        dataIndex: 'writerUser',
        render: (writerUser: User) => <>{writerUser.nickname}</>,
      },
      {
        title: '공개여부',
        dataIndex: 'isPublish',
        render: (isPublish) => (isPublish ? <Tag color="success">공개</Tag> : <Tag>비공개</Tag>),
      },
      {
        title: '공개 날짜',
        dataIndex: 'publishedAt',
        render: (publishedAt: Date) => <>{publishedAt.toLocaleString()}</>,
      },
      { title: '우선순위', dataIndex: 'boardPriority' },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <SelectWeek
          value={search.weeks}
          onChange={(weeks) => setSearch((search) => ({ ...search, weeks }))}
        />

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="내용 / 닉네임"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(board) => ({
          onClick: () => {
            navigate(`/board/${board.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default BestLetter;
