import { Layout, Menu, Modal, message, type MenuProps } from 'antd';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  CheckCircleOutlined,
  DownloadOutlined,
  FireOutlined,
  FunnelPlotOutlined,
  IssuesCloseOutlined,
  LogoutOutlined,
  MailOutlined,
  NotificationOutlined,
  UserOutlined,
  SmileOutlined,
  MessageOutlined,
  GiftOutlined,
} from '@ant-design/icons';

import { ServiceType } from '~/classes/User';
import Path from '~/constants/Path';
import axios from '~/helper/axios';
import { useDispatch, useSelector } from '~/hooks/useRedux';
import { signOut } from '~/reducers/auth';

import { CenterContainer } from '../Styled/Containers';

const { Sider, Content } = Layout;
const { confirm } = Modal;

const LogoWrapper = styled(CenterContainer)`
  background-color: white;
  padding: 20px;
`;

const LayoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const email = useSelector((state) => state.auth.me.email);
  // const letterBoxs = useSelector((state) => state.letter.letterBoxs);
  // const serviceType = useSelector((state) => state.letter.serviceType);

  const showNewLetterBoxConfirm = () => {
    confirm({
      title: '새로운 주차를 시작하고 기존 주차에 편지쓰기를 마감하시겠습니까?',
      icon: <CheckCircleOutlined />,
      content: '다음 주차로 넘어갑니다. GoGo! 서비스 중에 연속으로 하면 큰일나요^^',
      onOk() {
        axios.post('/letter-box/weekly-box').then(() => {
          message.success('새 주차를 시작합니다.');
        });
      },
    });
  };

  const notificationTopicConfirm = () => {
    confirm({
      title: '편지 주차 변경 및 토픽 알림',
      icon: <CheckCircleOutlined />,
      content: '편지 주차 변경 및 토픽에 대한 알림이 발송됩니다.',
      onOk() {
        axios.post('/notification/topic').then(() => {
          message.success('주차 및 토픽 알림이 발송되었습니다.');
        });
      },
    });
  };

  const showConfirm = () => {
    confirm({
      title: '편지를 배정하고 이번 주차를 종료하시겠어요?',
      icon: <CheckCircleOutlined />,
      content: '검수 완료된 편지들을 배정합니다. GoGo! 서비스 중에 연속으로 하면 큰일나요^^',
      onOk() {
        axios.post('/letter-box/weekly-settlement').then(() => {
          message.success('배정을 완료했습니다.');
        });
      },
    });
  };

  const postLetterConfirm = () => {
    confirm({
      title: '편지 발송 시작 알림',
      icon: <CheckCircleOutlined />,
      content: '편지 발송 시작 알림 발송',
      onOk() {
        axios.post('/notification/post-letter').then(() => {
          message.success('편지 발송 알림이 발송되었습니다.');
        });
      },
    });
  };

  const bestLetterConfirm = () => {
    confirm({
      title: '모두의 편지 알림',
      icon: <CheckCircleOutlined />,
      content: '모두의 편지 알림 발송',
      onOk() {
        axios.post('/notification/best-letter').then(() => {
          message.success('모두의 편지 알림이 발송되었습니다.');
        });
      },
    });
  };

  const showUnlockLetters = () => {
    confirm({
      title: '배정된 편지 잠금 해제 및 알림 발송',
      icon: <CheckCircleOutlined />,
      content: '배정된 편지 잠금 해제 및 알림 발송하기',
      async onOk() {
        await axios.post('/notification/open-letter');
        await axios.post('/letter-box/unlock-letters');
        message.success('잠금을 해제했습니다.');
      },
    });
  };

  const closeLetterConfirm = () => {
    confirm({
      title: '편지 주차 마감 알림',
      icon: <CheckCircleOutlined />,
      content: '편지 주차 마감, 편지 안쓴사람들에게 알람이 발송된다.',
      onOk() {
        axios.post('/notification/close-letter').then(() => {
          message.success('잠금을 해제했습니다.');
        });
      },
    });
  };

  const showDownloadPDF = () => {
    confirm({
      title: '이번 주차 편지를 다운받으시겠어요?',
      icon: <DownloadOutlined />,
      content: '이번 주차 편지를 다운로드 합니다.',
      onOk() {
        message.success('다운로드를 시작합니다. 시간이 걸릴 수 있어요.');
        axios
          .get('/letter-box/weekly-box/pdf', {
            params: { serviceType: ServiceType.OFFICIAL, weeks: 2 },
          })
          .then(() => {
            message.success('다운로드를 완료했습니다!');
          });
      },
    });
  };

  const items: MenuProps['items'] = useMemo(
    () =>
      [
        {
          key: Path.LETTERS,
          label: '편지목록',
          onClick: () => navigate(Path.LETTERS),
          icon: <MailOutlined />,
        },
        {
          key: '게시판',
          label: '게시판',
          icon: <NotificationOutlined />,
          children: [
            email === 'hello@letterunknown.com' && {
              key: Path.NOTICE,
              label: '공지사항',
              onClick: () => navigate(Path.NOTICE),
            },
            {
              key: Path.BEST_LETTER,
              label: '모두의 편지',
              onClick: () => navigate(Path.BEST_LETTER),
            },
            email === 'hello@letterunknown.com' && {
              key: Path.WEEKLY_TOPIC,
              label: '금주의 주제',
              onClick: () => navigate(Path.WEEKLY_TOPIC),
            },
          ],
        },
        {
          key: '편지 검수',
          label: '편지 검수',
          icon: <FunnelPlotOutlined />,
          children: [
            {
              key: Path.SCREENING,
              label: '편지 1차 검수',
              onClick: () => navigate(Path.SCREENING),
            },
            {
              key: Path.CONFIRM,
              label: '편지 최종 검수',
              onClick: () => navigate(Path.CONFIRM),
            },
            {
              key: Path.BEST_LETTER_CONFIRM,
              label: '모두의 편지 선정',
              onClick: () => navigate(Path.BEST_LETTER_CONFIRM),
            },
          ],
        },
        {
          key: Path.USER,
          label: '사용자',
          icon: <UserOutlined />,
          onClick: () => navigate(Path.USER),
        },
        {
          key: Path.COUPON,
          label: '쿠폰',
          icon: <GiftOutlined />,
          onClick: () => navigate(Path.COUPON),
        },
        {
          key: Path.SEASON,
          label: '시즌',
          icon: <SmileOutlined />,
          onClick: () => navigate(Path.SEASON),
        },
        email === 'hello@letterunknown.com' && {
          key: Path.DIRECT_NOTIFICATION,
          label: '알림 보내기',
          icon: <MessageOutlined />,
          onClick: () => navigate(Path.DIRECT_NOTIFICATION),
        },
        {
          key: Path.BLOCK,
          label: '신고',
          icon: <IssuesCloseOutlined />,
          onClick: () => navigate(Path.BLOCK),
        },
        { type: 'divider' },
        {
          key: 'sign-out',
          label: 'Sign Out',
          icon: <LogoutOutlined />,
          onClick: () => dispatch(signOut()),
        },

        email === 'hello@letterunknown.com' && {
          key: '새주차 시작',
          label: '월/00시 새주차 시작',
          icon: <FireOutlined />,
          onClick: showNewLetterBoxConfirm,
        },
        email === 'hello@letterunknown.com' && {
          key: '편지 주차변경/토픽 알림',
          label: '월/09시 편지 주차변경/토픽 알림',
          icon: <FireOutlined />,
          onClick: notificationTopicConfirm,
        },
        email === 'hello@letterunknown.com' && {
          key: '편지 강제 배정',
          label: '월/12시 편지 강제 배정',
          icon: <FireOutlined />,
          onClick: showConfirm,
        },
        email === 'hello@letterunknown.com' && {
          key: '편지 발송 알림',
          label: '화/16시 편지 발송 알림',
          icon: <FireOutlined />,
          onClick: postLetterConfirm,
        },
        email === 'hello@letterunknown.com' && {
          key: '모두의 편지 알림',
          label: '목/16시 모두의 편지 알림',
          icon: <FireOutlined />,
          onClick: bestLetterConfirm,
        },
        email === 'hello@letterunknown.com' && {
          key: '편지 강제 언락',
          label: '금/18시 편지 강제 언락 알림',
          icon: <FireOutlined />,
          onClick: showUnlockLetters,
        },
        email === 'hello@letterunknown.com' && {
          key: '편지 주차 마감',
          label: '일/20시 편지 주차 마감 알림',
          icon: <FireOutlined />,
          onClick: closeLetterConfirm,
        },

        // email === 'hello@letterunknown.com' && {
        //   key: 'PDF 다운로드',
        //   label: 'PDF 다운로드',
        //   icon: <DownloadOutlined />,
        //   onClick: showDownloadPDF,
        // },
      ].filter(Boolean) as MenuProps['items'],
    [dispatch, navigate, email],
  );

  return (
    <Layout>
      <Sider width={300} style={{ maxHeight: 'calc(100vh - 70px)' }}>
        <LogoWrapper>
          <img src={'/img/logo.svg'} height={30} />
        </LogoWrapper>

        <Menu
          mode="inline"
          style={{ height: '100%' }}
          items={items}
          defaultOpenKeys={['게시판', '편지 검수']}
          selectedKeys={[location.pathname]}
        />
      </Sider>
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
