import { Select } from 'antd';

import { useSelector } from '~/hooks/useRedux';

interface Props {
  value?: number;
  onChange: (weeks: number) => void;
}

const SelectWeek = ({ value, onChange }: Props) => {
  const letterBoxs = useSelector((state) => state.letter.letterBoxs);

  return (
    <Select
      allowClear
      value={value}
      placeholder="주차별"
      style={{ width: 100 }}
      onChange={onChange}
    >
      {letterBoxs.map((letterBox) => (
        <Select.Option key={letterBox.id} value={letterBox.weeks}>
          {letterBox.weeks}주차
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectWeek;
