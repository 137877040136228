import { Button, Divider, Input, message, Typography } from 'antd';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useDispatch } from '~/hooks/useRedux';
import { signIn } from '~/reducers/auth';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
`;

const SignIn = () => {
  const dispatch = useDispatch();

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required().email(),
      password: Yup.string().required().min(8).max(32),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(signIn(values)).unwrap();

        message.success('로그인 성공');
      } catch (e) {
        if (e !== 'Unauthorized Error') {
          message.error('아이디 혹은 비밀번호가 틀렸습니다');
        }
      }
    },
  });

  return (
    <Wrapper>
      <Typography.Title>Unknown Letter</Typography.Title>
      <Typography.Title level={3}>Admin</Typography.Title>

      <br />
      <form onSubmit={handleSubmit}>
        <Typography.Text>아이디</Typography.Text>
        <Input value={values.email} onChange={handleChange} name="email" placeholder="이메일" />

        <Typography.Text>비밀번호</Typography.Text>
        <Input
          value={values.password}
          onChange={handleChange}
          name="password"
          placeholder="비밀번호"
          type="password"
        />

        <Divider />

        <Button type="primary" size="large" block htmlType="submit">
          로그인
        </Button>
      </form>
    </Wrapper>
  );
};

export default SignIn;
