export default class PaginationRequest {
  page?: number;
  limit?: number;

  constructor(paginationRequest: PaginationRequest) {
    const defaultLimit = localStorage.getItem('limit') ? +localStorage.getItem('limit')! : 10;

    this.page = paginationRequest.page || 1;
    this.limit = paginationRequest.limit || defaultLimit;
  }
}
