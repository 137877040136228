import { UserRole } from './UserRole';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum ServiceType {
  OFFICIAL = 'OFFICIAL',
}

export enum DeletedReason {
  WITHDRAWAL = 'WITHDRAWAL', // 사용자 탈퇴
  USAGE_RESTRICTIONS = 'USAGE_RESTRICTIONS', // 이용 제한, 제재
}

export default class User {
  id: number;
  email: string;
  realname: string;
  phone: string;
  birthday: string;
  gender: Gender;
  nickname: string;
  address: string;
  addressDetail: string;
  zipCode: string;
  serviceType: ServiceType;
  deletedReason?: DeletedReason;
  comment?: string;
  isPaid: boolean;
  agreeMarketing: boolean;
  userRoles?: UserRole[];
  steppayId?: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(user: User) {
    this.id = user.id;
    this.email = user.email;
    this.realname = user.realname;
    this.phone = user.phone;
    this.birthday = user.birthday;
    this.gender = user.gender;
    this.nickname = user.nickname;
    this.address = user.address;
    this.addressDetail = user.addressDetail;
    this.zipCode = user.zipCode;
    this.serviceType = user.serviceType;
    this.deletedReason = user.deletedReason;
    this.comment = user.comment;
    this.isPaid = user.isPaid;
    this.agreeMarketing = user.agreeMarketing;
    this.userRoles = user.userRoles && user.userRoles.map((userRole) => new UserRole(userRole));
    this.steppayId = user.steppayId;
    this.createdAt = new Date(user.createdAt);
    this.updatedAt = new Date(user.updatedAt);
  }
}
