import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import LetterAPI from '~/api/LetterAPI';
import LetterBox from '~/classes/LetterBox';
import { ServiceType } from '~/classes/User';

export const letterBoxs = createAsyncThunk(
  'letter/letterBoxs',
  async (serviceType: ServiceType, { rejectWithValue }) => {
    try {
      const letterBoxs = await LetterAPI.letterBoxs(serviceType);

      return letterBoxs;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data || err.message);
    }
  },
);

export interface LetterState {
  serviceType: ServiceType;
  letterBoxs: LetterBox[];
}

const initialState: LetterState = {
  serviceType: ServiceType.OFFICIAL,
  letterBoxs: [],
};

export const letter = createSlice({
  name: 'letter',
  initialState,
  reducers: {},
  extraReducers: {
    [letterBoxs.pending.type]: (state, action) => {},
    [letterBoxs.fulfilled.type]: (state, { payload: letterBoxs }: PayloadAction<LetterBox[]>) => {
      state.letterBoxs = letterBoxs;
    },
    [letterBoxs.rejected.type]: (state, action: PayloadAction) => {},
  },
});

export default letter.reducer;
