import { Button, Input, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { useGetBoardListQuery } from '~/api/BoardAPI';
import { Board, BoardType } from '~/classes/Board';
import { SearchBoardDto } from '~/classes/Dto/SearchBoardDto';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import useSearch from '~/hooks/useSearch';

const Notice = () => {
  const navigate = useNavigate();

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchBoardDto>('noticeSearch', {
    boardType: BoardType.NOTICE,
  });
  const { data } = useGetBoardListQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Board>>(
    () => [
      { title: 'Notice ID', dataIndex: 'id' },
      { title: '제목', dataIndex: 'title' },
      {
        title: '공지 본문',
        dataIndex: 'content',
        render: (content: string) => <>{content.slice(0, 25)}</>,
      },
      {
        title: '공개여부',
        dataIndex: 'isPublish',
        render: (isPublish) => (isPublish ? <Tag color="success">공개</Tag> : <Tag>비공개</Tag>),
      },
      {
        title: '공개 날짜',
        dataIndex: 'publishedAt',
        render: (publishedAt: Date) => <>{publishedAt.toLocaleString()}</>,
      },
      { title: '우선순위', dataIndex: 'boardPriority' },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <div></div>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="내용 / 닉네임"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(Path.BOARD_CREATE)}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(board) => ({
          onClick: () => {
            navigate(`/board/${board.id}`);
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default Notice;
