import { Button, DatePicker, Form, Input, message, Select, Switch, TimePicker } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetBoardQuery, useLazyPatchBoardQuery } from '~/api/BoardAPI';
import { Board, BoardPriority, BoardType } from '~/classes/Board';
import { Param } from '~/classes/Param';
import { FlexEndContainer } from '~/components/Styled/Containers';
import { TextArea } from '~/components/Texts';
import Path from '~/constants/Path';
import { useSelector } from '~/hooks/useRedux';

const BoardDetail = () => {
  const navigate = useNavigate();
  const { boardId } = useParams<Param>();
  const letterBoxs = useSelector((state) => state.letter.letterBoxs);

  const { data: board } = useGetBoardQuery(+boardId!);
  const [patchBoard] = useLazyPatchBoardQuery();

  const { values, errors, handleChange, handleSubmit, setFieldValue, setValues } = useFormik<Board>(
    {
      initialValues: board || ({} as any),
      validationSchema: Yup.object({
        title: Yup.string().required(),
        content: Yup.string().required(),
      }),
      onSubmit: async (values) => {
        console.log(values);

        const { isSuccess, isError } = await patchBoard(values);

        if (isSuccess) {
          message.success('글을 수정했습니다');
          switch (values.boardType) {
            case BoardType.NOTICE:
              navigate(Path.NOTICE);

              break;
            case BoardType.BEST_LETTER:
              navigate(Path.BEST_LETTER);

              break;
            case BoardType.WEEKLY_TOPIC:
              navigate(Path.WEEKLY_TOPIC);

              break;
          }
        }

        if (isError) {
          message.error('글 수정에 실패했습니다');
        }
      },
    },
  );

  const onChange = (key: keyof Board) => (e: any) => setFieldValue(key, e);

  useEffect(() => {
    if (board) {
      setValues(board);
    }
  }, [board, setValues]);

  if (!board) {
    return <></>;
  }

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="Notice ID">
        <Input disabled value={board.id} />
      </Form.Item>

      <Form.Item label="타입">
        <Select disabled value={values.boardType} onSelect={onChange('boardType')}>
          <Select.Option key={BoardType.NOTICE} value={BoardType.NOTICE}>
            공지사항
          </Select.Option>
          <Select.Option key={BoardType.WEEKLY_TOPIC} value={BoardType.WEEKLY_TOPIC}>
            금주의 주제
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="제목" help={errors.title}>
        <Input value={values.title} name="title" onChange={handleChange} />
      </Form.Item>

      {values.boardType === BoardType.WEEKLY_TOPIC && (
        <Form.Item label="작가명">
          <Input
            value={values.weeklyTopicWriterName}
            name="weeklyTopicWriterName"
            onChange={handleChange}
          />
        </Form.Item>
      )}

      <Form.Item label="미리보기" help={errors.preview}>
        <TextArea value={values.preview} name="preview" onChange={handleChange} rows={4} />
      </Form.Item>

      <Form.Item label="본문" help={errors.content}>
        <TextArea value={values.content} name="content" onChange={handleChange} rows={10} />
      </Form.Item>

      <Form.Item label="공개 여부" valuePropName="checked">
        <Switch checked={values.isPublish} onChange={onChange('isPublish')} />
      </Form.Item>

      <Form.Item label="공개 시간" valuePropName="checked">
        <DatePicker
          allowClear={false}
          value={moment(values.publishedAt)}
          onChange={onChange('publishedAt')}
        />
        <TimePicker
          allowClear={false}
          value={moment(values.publishedAt)}
          onChange={onChange('publishedAt')}
        />
      </Form.Item>

      <Form.Item label="우선순위">
        <Select value={values.boardPriority} onSelect={onChange('boardPriority')}>
          <Select.Option key={BoardPriority.HIGH} value={BoardPriority.HIGH}>
            HIGH
          </Select.Option>
          <Select.Option key={BoardPriority.MEDIUM} value={BoardPriority.MEDIUM}>
            MEDIUM
          </Select.Option>
          <Select.Option key={BoardPriority.LOW} value={BoardPriority.LOW}>
            LOW
          </Select.Option>
        </Select>
      </Form.Item>

      {values.boardType !== BoardType.NOTICE && (
        <Form.Item label="주차">
          <Select value={values.letterBoxId} onSelect={onChange('letterBoxId')}>
            {letterBoxs
              .map((letterBox) => (
                <Select.Option key={letterBox.id} value={letterBox.id}>
                  {letterBox.weeks}
                </Select.Option>
              ))
              .reverse()}
          </Select>
        </Form.Item>
      )}

      <Form.Item label="작성 시간">
        <DatePicker disabled value={moment(board.createdAt)} />
        <TimePicker disabled value={moment(board.createdAt)} />
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            수정
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default BoardDetail;
