import LetterBox from './LetterBox';
import User from './User';

export enum BoardType {
  NOTICE = 'NOTICE',
  BEST_LETTER = 'BEST_LETTER',
  WEEKLY_TOPIC = 'WEEKLY_TOPIC',
}

export enum BoardPriority {
  LOW,
  MEDIUM,
  HIGH,
}

export class Board {
  id: number;
  writerUserId: number;
  writerUser?: User;
  boardType: BoardType;
  title: string;
  preview: string;
  content: string;
  weeklyTopicWriterName: string;
  isPublish: boolean;
  publishedAt: Date;
  boardPriority: BoardPriority;
  letterBoxId: number;
  letterBox?: LetterBox;
  createdAt: Date;
  updatedAt: Date;

  constructor(board: Board) {
    this.id = board.id;
    this.writerUserId = board.writerUserId;
    this.writerUser = board.writerUser && new User(board.writerUser);
    this.boardType = board.boardType;
    this.title = board.title;
    this.preview = board.preview;
    this.content = board.content;
    this.weeklyTopicWriterName = board.weeklyTopicWriterName;
    this.isPublish = board.isPublish;
    this.publishedAt = new Date(board.publishedAt);
    this.boardPriority = board.boardPriority;
    this.letterBoxId = board.letterBoxId;
    this.letterBox = board.letterBox && new LetterBox(board.letterBox);
    this.createdAt = new Date(board.createdAt);
    this.updatedAt = new Date(board.updatedAt);
  }
}
