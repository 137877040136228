import { createApi } from '@reduxjs/toolkit/query/react';

import { BlockList } from '~/classes/BlockList';
import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import { axiosBaseQuery } from '~/helper/axios';

export const blockApi = createApi({
  reducerPath: 'blockApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getBlockList: builder.query<Pagination<BlockList>, PaginationRequest>({
      query: (params) => ({ url: '/block-list', method: 'get', params }),
      transformResponse: (data: Pagination<BlockList>) => new Pagination(data, BlockList),
    }),
  }),
});

export const { useGetBlockListQuery } = blockApi;
