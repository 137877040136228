import { Input, Space, Switch, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetLetterListQuery } from '~/api/LetterAPI';
import { SearchLetterDto } from '~/classes/Dto/SearchLetterDto';
import Letter, { LetterStatus, LetterStatusLabel } from '~/classes/Letter';
import SelectWeek from '~/components/SelectWeek';
import { SpaceBetweenContainer } from '~/components/Styled/Containers';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useSelector } from '~/hooks/useRedux';
import useSearch from '~/hooks/useSearch';

const Letters = () => {
  const navigate = useNavigate();

  const serviceType = useSelector((state) => state.letter.serviceType);

  const [pagination, setPage, setLimit] = usePaginationRequest();
  const [search, setSearch] = useSearch<SearchLetterDto>('lettersSearch', {
    serviceType,
    deleted: false,
  });
  const { data } = useGetLetterListQuery({ ...search, ...pagination });

  const columns = useMemo<ColumnsType<Letter>>(
    () => [
      { title: 'Letter ID', dataIndex: 'id' },
      {
        title: '주차',
        dataIndex: 'letterBox',
        render: (letterBox) => <>{letterBox.weeks}</>,
      },
      {
        title: '새편지/답장',
        dataIndex: 'isReply',
        render: (isReply) =>
          isReply ? <Tag color="processing">답장</Tag> : <Tag color="success">새편지</Tag>,
      },
      {
        title: '상태',
        dataIndex: 'letterStatus',
        render: (letterStatus, letter) => (
          <>
            {LetterStatusLabel[letterStatus]}{' '}
            {letter.letterDeleteStatus && (
              <Tooltip title={letter.letterDeleteStatus}>
                <Tag color="error">Deleted</Tag>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: '편지 본문',
        dataIndex: 'originContent',
        render: (originContent: string) => <>{originContent.slice(0, 25)}</>,
      },
      {
        title: '닉네임 (ID)',
        dataIndex: 'senderUser',
        render: (senderUser) => (
          <>
            {senderUser?.nickname} <Tag>{senderUser?.id}</Tag>
          </>
        ),
      },
      {
        title: '작성일',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => <>{createdAt.toLocaleString()}</>,
      },
    ],
    [],
  );

  return (
    <>
      <SpaceBetweenContainer>
        <Space>
          <SelectWeek
            value={search.weeks}
            onChange={(weeks) => setSearch((search) => ({ ...search, weeks }))}
          />

          <Switch
            checked={search.deleted}
            onChange={(deleted) => setSearch((search) => ({ ...search, deleted }))}
            checkedChildren={'삭제'}
            unCheckedChildren={'삭제'}
          />
        </Space>

        <Space>
          <Input.Search
            value={search.keyword}
            allowClear
            placeholder="내용 / 닉네임"
            onChange={(e) => setSearch((search) => ({ ...search, keyword: e.target.value }))}
          />
        </Space>
      </SpaceBetweenContainer>

      <Table
        rowKey={'id'}
        dataSource={data?.data}
        columns={columns}
        rowClassName={'cursor'}
        onRow={(letter) => ({
          onClick: () => {
            if (
              [LetterStatus.SUBMITTED, LetterStatus.SCREENING_OPERATOR_FAILURE].includes(
                letter.letterStatus,
              )
            ) {
              navigate(`/letter-screening/${letter.id}`);
            } else {
              navigate(`/letter-confirm/${letter.id}`);
            }
          },
        })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: data?.count,
          onChange: (page) => setPage(page),
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    </>
  );
};

export default Letters;
