import { Button, DatePicker, Form, Input, message, Select, Switch, TimePicker } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetUserQuery, useLazyPatchUserQuery } from '~/api/UserAPI';
import { Param } from '~/classes/Param';
import User, { DeletedReason } from '~/classes/User';
import { FlexEndContainer } from '~/components/Styled/Containers';
import Path from '~/constants/Path';

const UserDetail = () => {
  const navigate = useNavigate();
  const { userId } = useParams<Param>();

  const { data: user } = useGetUserQuery(+userId!);
  const [patchUser] = useLazyPatchUserQuery();

  const { values, handleChange, handleSubmit, setFieldValue, setValues } = useFormik<User>({
    initialValues: user || ({} as any),
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      console.log(values);

      const { isSuccess, isError } = await patchUser(values);

      if (isSuccess) {
        message.success('사용자 정보를 수정했습니다.');
        navigate(Path.USER);
      }

      if (isError) {
        message.error('사용자 정보 수정을 실패했습니다.');
      }
    },
  });

  const onChange = (key: keyof User) => (e: any) => setFieldValue(key, e);

  useEffect(() => {
    if (user) {
      setValues(user);
    }
  }, [user, setValues]);

  if (!user) {
    return <></>;
  }

  return (
    <Form labelCol={{ span: 4 }} onFinish={handleSubmit}>
      <Form.Item label="User ID">
        <Input disabled value={user.id} />
      </Form.Item>

      <Form.Item label="이메일">
        <Input disabled value={user.email} />
      </Form.Item>

      <Form.Item label="이름">
        <Input disabled value={user.realname} />
      </Form.Item>

      <Form.Item label="사용자 닉네임">
        <Input disabled value={user.nickname} />
      </Form.Item>

      <Form.Item label="생년월일">
        <Input disabled value={user.birthday} />
      </Form.Item>

      <Form.Item label="휴대전화">
        <Input disabled value={user.phone} />
      </Form.Item>

      <Form.Item label="성별">
        <Input disabled value={user.gender} />
      </Form.Item>

      <Form.Item label="steppayId">
        <Input disabled value={user.steppayId} />
      </Form.Item>

      <Form.Item label="마케팅 수신 동의" valuePropName="checked">
        <Switch disabled checked={user.agreeMarketing} />
      </Form.Item>

      <Form.Item label="가입일자">
        <DatePicker disabled value={moment(values.createdAt)} />
        <TimePicker disabled value={moment(values.createdAt)} />
      </Form.Item>

      <Form.Item label="삭제 이유">
        <Select value={values.deletedReason} onSelect={onChange('deletedReason')}>
          <Select.Option value={null}> </Select.Option>
          {Object.keys(DeletedReason)
            .map((deletedReason) => (
              <Select.Option key={deletedReason} value={deletedReason}>
                {deletedReason}
              </Select.Option>
            ))
            .reverse()}
        </Select>
      </Form.Item>

      <Form.Item label="댓글">
        <Input value={values.comment} name="comment" onChange={handleChange} />
      </Form.Item>

      <FlexEndContainer>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            수정
          </Button>
        </Form.Item>
      </FlexEndContainer>
    </Form>
  );
};

export default UserDetail;
